/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import type Chart from 'embercom/models/reporting/custom/chart';
import generateUUID from 'embercom/lib/uuid-generator';
// @ts-ignore no type declaration available for ember-copy
import { copy } from 'ember-copy';
import { VIZ_TYPE_TO_ICON_MAPPING } from 'embercom/models/reporting/custom/visualization-options';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type IntlService from 'embercom/services/intl';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type Report from 'embercom/models/reporting/custom/report';
import { cached } from 'tracked-toolbox';
import { indexBy } from 'underscore';

export interface ChartTemplate extends Chart {
  icon: InterfaceIconName;
  supportsCustomAggregations: boolean;
}

export interface ReportTemplate extends Report {
  icon: InterfaceIconName;
  featureKey: string;
  templateId: string;
}

export const REPORT_TEMPLATE_TO_ICON_MAPPING: { [key: string]: InterfaceIconName } = {
  ['calls']: 'phone',
  ['conversations']: 'chat-bubble',
  ['conversation_tags']: 'tag',
  ['effectiveness']: 'line-chart',
  ['fin_ai_agent']: 'fin',
  ['responsiveness']: 'bar-charts',
  ['team_inbox_performance']: 'multiple-people',
  ['teammate_performance']: 'person',
  ['tickets']: 'ticket',
};

export default class ReportingTemplates extends Service {
  @service declare appService: any;
  @service declare permissionsService: any;
  @service declare store: any;
  @service declare intl: IntlService;
  @service declare reportingMetrics: ReportingMetrics;

  chartTemplates: ChartTemplate[] = [];
  reportTemplates: ReportTemplate[] = [];
  groupedTemplatesByReportType: any = {};

  private isSetup = false;

  @cached
  get reportTemplatesById(): Record<string, ReportTemplate> {
    return indexBy(this.reportTemplates, 'templateId');
  }

  get canViewCustomReports() {
    if (this.appService.app.canShareReportsInternally) {
      return this.permissionsService.currentAdminCan('can_access_reporting');
    }
    return this.permissionsService.currentAdminCan('can_reporting__custom_reports__read');
  }

  async loadTemplates() {
    if (!this.isSetup && this.canViewCustomReports) {
      await taskFor(this.reportingMetrics.setup).perform(); // Ensure metrics are loaded first as we depend on them
      await taskFor(this.fetchTemplates).perform();
      this.isSetup = true;
      this.groupedTemplatesByReportType = this.groupTemplatesByReportType();
    }
  }

  @dropTask
  *fetchTemplates(): TaskGenerator<void> {
    let results = yield this.fetchData('/ember/reporting/custom_reports/templates');

    this.chartTemplates = results['charts'].map((template: any) => {
      return this.chartFromTemplateDefinition(template);
    });
    this.reportTemplates = results['reports'].map((template: any) => {
      return this.reportFromTemplateDefinition(template);
    });
  }

  private chartFromTemplateDefinition(template: any) {
    let chart = this.store.normalize(
      'reporting/custom/chart',
      this.sanitizedChartTemplateDefinition(template),
    );
    let metricIds = chart.data.attributes.chartSeries.map((series: any) => {
      return series.metricId;
    });
    return {
      ...chart.data.attributes,
      icon: VIZ_TYPE_TO_ICON_MAPPING[chart.data.attributes.visualizationType],
      supportsCustomAggregations: this.usesCustomAggregations(metricIds),
    };
  }

  private reportFromTemplateDefinition(template: any): ReportTemplate {
    let report = this.store.normalize(
      'reporting/custom/report',
      this.sanitizedReportTemplateDefinition(template),
    );
    return {
      ...report.data.attributes,
      id: report.data.id,
      templateId: template.template_id,
      featureKey: template.feature_key,
      icon: template.icon || 'bar-charts',
      charts: report.included.map((chart: any) => {
        return { ...chart.attributes, id: chart.id };
      }),
    };
  }

  private usesCustomAggregations(metricIds: string[]) {
    return metricIds.some((metricId: string) => {
      return this.reportingMetrics.metricSupportsCustomAggregations(metricId);
    });
  }

  async fetchData(url: string): Promise<any> {
    return await ajax({
      url,
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
      },
    });
  }

  searchCharts(searchTerm: string): ChartTemplate[] {
    if (!searchTerm) {
      return this.chartTemplates;
    }

    return this.chartTemplates.filter((item: ChartTemplate) => {
      return item.title.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }

  searchChartsWithFilters(searchTerm: string, chartType: string, reportType: string): any {
    if (!searchTerm && chartType === 'any' && reportType === 'any') {
      return this.groupedTemplatesByReportType;
    }

    return this.groupedTemplatesByReportType.reduce((filteredTemplates: any[], group: any) => {
      if (reportType !== 'any' && group.reportId !== reportType) {
        return filteredTemplates;
      }

      let chartTemplates = group.chartTemplates.filter((chart: ChartTemplate) => {
        let matchesSearchTerm = searchTerm
          ? chart.title.toLowerCase().includes(searchTerm.toLowerCase())
          : true;
        let matchesChartType = chartType !== 'any' ? chart.visualizationType === chartType : true;
        return matchesSearchTerm && matchesChartType;
      });

      if (chartTemplates.length > 0) {
        filteredTemplates.push({
          ...group,
          chartTemplates,
        });
      }

      return filteredTemplates;
    }, []);
  }

  searchReports(searchTerm: string): ReportTemplate[] {
    if (!searchTerm) {
      return this.reportTemplates;
    }

    return this.reportTemplates.filter((item: ReportTemplate) => {
      return item.title.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }

  groupTemplatesByReportType(): any {
    let templateMap = this.chartTemplates.reduce(
      (map: Record<string, ChartTemplate>, template: ChartTemplate) => {
        map[template.templateId] = template;
        return map;
      },
      {},
    );
    let usedTemplateIds = new Set();

    let reportResults = this.reportTemplates.map((report: ReportTemplate) => {
      let chartTemplates = report.charts
        .map((chart: Chart) => templateMap[chart.templateId])
        .filter((template: ChartTemplate) => !!template);

      chartTemplates.forEach((template: ChartTemplate) => usedTemplateIds.add(template.templateId));

      return {
        title: report.title,
        reportId: report.templateId,
        chartTemplates,
      };
    });

    let unusedTemplates = this.chartTemplates.filter(
      (template: any) => !usedTemplateIds.has(template.templateId),
    );

    if (unusedTemplates.length > 0) {
      reportResults.push({
        title: this.intl.t('reporting.custom-reports.chart.sidebar-filters.reports.other'),
        reportId: 'other',
        chartTemplates: unusedTemplates,
      });
    }

    return reportResults;
  }

  sanitizedChartTemplateDefinition(template: any) {
    let sanitizedTemplateDefinition = copy(template, true);
    // Sanitize chart ids so we don't accidentally overwrite other custom charts in the store
    let chartId = `chart-template-${generateUUID()}`;
    sanitizedTemplateDefinition.id = chartId;
    return sanitizedTemplateDefinition;
  }

  sanitizedReportTemplateDefinition(template: any) {
    let sanitizedTemplateDefinition = copy(template, true);
    // Sanitize IDs so we don't accidentally overwrite other custom reports in the store
    let reportId = `report-template-${generateUUID()}`;
    sanitizedTemplateDefinition.id = reportId;
    sanitizedTemplateDefinition['charts'].forEach((chart: any, index: number) => {
      chart.id = `chart-${reportId}-${index}`;
    });
    return sanitizedTemplateDefinition;
  }
}

declare module '@ember/service' {
  interface Registry {
    reportingTemplates: ReportingTemplates;
    'reporting-templates': ReportingTemplates;
  }
}
