/* RESPONSIBLE TEAM: team-product-exploration */
import { action } from '@ember/object';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import storage from 'embercom/vendor/intercom/storage';

const NAV_BAR_LOCAL_STORAGE_KEY = 'primary-nav-bar-pinned';
export const NAV_BAR_COLLAPSED_WIDTH_PX = 52;
export const NAV_BAR_EXPANDED_WIDTH_PX = 212;
export const WINDOW_PADDING_RIGHT_PX = 4;

export default class CommonPrimaryNavBarService extends Service {
  @tracked workspaceID: string | null = null;
  @tracked pinned = false;
  @tracked isProductIAEnabled = false;

  initialise(workspaceID: string) {
    this.workspaceID = workspaceID;
    this.isProductIAEnabled = true;
    this.pinned = storage.get(`${NAV_BAR_LOCAL_STORAGE_KEY}-${workspaceID}`) ?? false;
  }

  get navBarWidth() {
    if (this.pinned) {
      return NAV_BAR_EXPANDED_WIDTH_PX;
    }
    return NAV_BAR_COLLAPSED_WIDTH_PX;
  }

  get mainContentIAOverrideClasses() {
    let classes = [];
    if (this.isProductIAEnabled) {
      classes.push('main__content-outlet-ia-overrides');

      if (this.pinned) {
        classes.push('main__content-outlet-ia-pinned-override');
      }
    }
    return classes.join(' ');
  }

  get expandedMainContentIAOverrideClasses() {
    let classes = [];
    if (this.isProductIAEnabled) {
      classes.push('o__without-submenu-ia-override');

      if (this.pinned) {
        classes.push('o__without-submenu-ia-pinned-override');
      }
    }
    return classes.join(' ');
  }

  get profileIAOverrideClasses() {
    let classes = [];
    if (this.isProductIAEnabled) {
      classes.push('rounded-tr-xl profile__sticky__ia-overrides');

      if (this.pinned) {
        classes.push('profile__sticky__ia-pinned-overrides');
      }
    }
    return classes.join(' ');
  }

  get expandedProfileIAOverrideClasses() {
    let classes = [];
    if (this.isProductIAEnabled) {
      classes.push('rounded-tl-xl profile__sticky__collapsed__ia-overrides');

      if (this.pinned) {
        classes.push('profile__sticky__collapsed__ia-pinned-overrides');
      }
    }
    return classes.join(' ');
  }

  @action
  toggleNavBarPinStatus() {
    this.pinned = !this.pinned;
    storage.set(`${NAV_BAR_LOCAL_STORAGE_KEY}-${this.workspaceID}`, this.pinned);
  }
}

declare module '@ember/service' {
  interface Registry {
    CommonPrimaryNavBarService: CommonPrimaryNavBarService;
    'common-primary-nav-bar-service': CommonPrimaryNavBarService;
  }
}
