/* RESPONSIBLE TEAM: team-conversational-knowledge */

import Service, { inject as service } from '@ember/service';
import { type BlockList } from '@intercom/interblocks.ts';
import { blockToText } from 'embercom/components/inbox2/common/blocks-as-text';
import type Conversation from 'embercom/objects/inbox/conversation';
import type Session from 'embercom/services/session';
import { TrackedMap, tracked } from 'tracked-built-ins';
import type IntlService from 'embercom/services/intl';
import { post } from 'embercom/lib/ajax';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type Snackbar from 'embercom/services/snackbar';
import type RouterService from '@ember/routing/router-service';
import { EntityType } from 'embercom/models/data/entity-types';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import storage from 'embercom/vendor/intercom/storage';

const MIN_REPLY_LENGTH = 10;

type QuestionAnswerRequestSuccessWireFormat = {
  success: true;
  conversation_id: number | string;
  question: string;
  answer: string;
  locale: string;
  suggested_question_answer_id: number | undefined;
  max_suggestions_per_day?: number;
};

type QuestionAnswerRequestFailureWireFormat = {
  success: false;
  conversation_id: number | string;
  failure_reason: string;
};

type QuestionAnswerResponseWireFormat =
  | QuestionAnswerRequestSuccessWireFormat
  | QuestionAnswerRequestFailureWireFormat;

type QuestionAnswer = {
  question: string;
  answer: string;
  locale: string | undefined;
};

type SuggestedQuestionAnswer = {
  id: number | undefined;
  question: string;
  answer: string;
  locale: string;
};

type RequestStatus =
  | { status: 'generating' }
  | { status: 'response-received'; response: QuestionAnswerRequestSuccessWireFormat }
  | { status: 'dismissed' }
  | { status: 'submitted' };

export type DismissReason =
  | 'not-now'
  | 'too-complex'
  | 'content-already-exists-in-article'
  | 'suggestion-not-accurate'
  | 'other';

export default class FinQuestionAnswers extends Service {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;
  @service declare snackbar: Snackbar;
  @service declare router: RouterService;

  @tracked requests = new TrackedMap<Conversation['id'], RequestStatus>();

  @tracked currentQuestionAnswer?: {
    conversationId: number;
    questionAnswer: SuggestedQuestionAnswer;
    resolve: (value: unknown) => void;
  };

  private MAX_MODAL_SHOWN_COUNT = 4;

  get qa_modal_shown_count_key() {
    return `fin-question-answer-modal-shown-count-${this.session.workspace.id}-${this.session.teammate.id}`;
  }

  get qa_modal_shown_count_today(): number {
    let storedValues = storage.get(this.qa_modal_shown_count_key);
    if (storedValues) {
      let [date, count] = storedValues.split('|');
      if (date === new Date().toISOString().split('T')[0]) {
        return Number(count) || 0;
      }
      return 0;
    }
    return 0;
  }

  private increment_qa_modal_shown_count_today() {
    let currentCount = this.qa_modal_shown_count_today;

    storage.set(
      this.qa_modal_shown_count_key,
      `${new Date().toISOString().split('T')[0]}|${currentCount + 1}`,
    );
  }

  get isConversationExtractionEnabled() {
    return this.session.workspace.isFeatureEnabled('conversation-extraction-experiment');
  }

  get isEnabled() {
    let canFinUseConversationContent = this.session.workspace.canFinUseConversationContent;
    return !this.isConversationExtractionEnabled && canFinUseConversationContent;
  }

  isSilent(response: QuestionAnswerRequestSuccessWireFormat) {
    if (this.session.workspace.isFeatureEnabled('fin-inbox-question-answers-silent')) {
      return true;
    }

    let max_suggestions_per_day = response.max_suggestions_per_day || this.MAX_MODAL_SHOWN_COUNT;
    let modalShownCount = this.qa_modal_shown_count_today;
    return modalShownCount >= max_suggestions_per_day;
  }

  private blocksToText(blocks: BlockList): string {
    return blocks.map((block) => blockToText(block, this.intl)).join(' ');
  }

  async maybeShowModal(conversationId: Conversation['id']) {
    if (!this.isEnabled) {
      return;
    }

    let requestForConversation = this.requestForConversation(conversationId);
    if (requestForConversation?.status === 'generating') {
      this.intercomEventService.trackAnalyticsEvent({
        object: 'inbox_fin_question_answers',
        action: 'show_modal_request_in_flight',
        conversation_id: conversationId,
      });
    }

    let questionAnswerForConversation = this.questionAnswerForConversation(conversationId);
    if (!questionAnswerForConversation || requestForConversation?.status !== 'response-received') {
      return;
    }

    let isSilent = this.isSilent(requestForConversation.response);

    this.intercomEventService.trackAnalyticsEvent({
      object: 'inbox_fin_question_answers',
      action: 'show_modal',
      conversation_id: conversationId,
      silent: isSilent,
    });

    // don't show the modal, just track the event to say we would have done
    // so we can gague how often we would have shown it before enabling it fully
    if (isSilent) {
      this.closeModal();
      return;
    }

    this.increment_qa_modal_shown_count_today();

    let questionAnswer = questionAnswerForConversation;
    return new Promise((resolve) => {
      this.currentQuestionAnswer = {
        conversationId,
        questionAnswer,
        resolve,
      };
    });
  }

  private closeModal() {
    this.currentQuestionAnswer?.resolve?.(true);
    this.currentQuestionAnswer = undefined;
  }

  async maybeRequestQuestionAnswer(
    conversation: Conversation,
    replyBlocks: BlockList,
    replyParts: RenderablePart[],
  ) {
    if (!this.isEnabled) {
      return;
    }

    let conversationId = conversation.id;

    // TODO - look at other replies too?
    if (this.blocksToText(replyBlocks).length < MIN_REPLY_LENGTH) {
      return;
    }

    // don't if we have already sent a request for this conversation
    if (this.requests.get(conversationId)) {
      return;
    }

    let part_id = replyParts[0]?.entityId;

    this.requests.set(conversationId, { status: 'generating' });

    try {
      let response = await post(
        `/ember/inbox/conversations/${conversationId}/fin_extract_qa?app_id=${this.session.workspace.id}`,
        {
          times_shown_today: this.qa_modal_shown_count_today,
        },
      );

      this.handleResponse(response, part_id);
    } catch (e) {
      console.error(e);
      this.handleResponse(
        {
          success: false,
          conversation_id: conversationId,
          failure_reason: `request-failed (${e.jqXHR.status})`,
        },
        part_id,
      );
    }
  }

  async maybeExtractContentFromConversation(conversationId: number, conversationState: string) {
    if (this.isConversationExtractionEnabled && conversationState === 'closed') {
      await this.extractContentFromConversation(conversationId);
    }
  }

  // This method bypasses CS rep confirmation modal and directly extracts content from the conversation
  async extractContentFromConversation(conversationId: number) {
    this.requests.set(conversationId, { status: 'generating' });

    try {
      await post(
        `/ember/inbox/conversations/${conversationId}/fin_extract_and_suggest_qa?app_id=${this.session.workspace.id}`,
      );
      this.requests.set(conversationId, { status: 'submitted' });
    } catch (e) {
      this.requests.delete(conversationId);
      console.error(e);
    }
  }

  // This will either be called when the request responds, or as the result of a Nexus event
  // as the requests may be more than 60s and so get killed more often than not
  handleResponse(response: QuestionAnswerResponseWireFormat, part_id: number | undefined) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'inbox_fin_question_answers',
      action: 'extract_request_completed',
      success: response.success,
      reason: response.success === false ? response.failure_reason : undefined,
      conversation_id: response.conversation_id,
      part_id,
    });

    if (!response.success) {
      // clear out the request for this conversation so there's an opportunity to try again
      // if the admin sends another reply at some point
      this.requests.delete(Number(response.conversation_id));
      return;
    }

    this.requests.set(Number(response.conversation_id), { status: 'response-received', response });
  }

  questionAnswerForConversation(
    conversationId: Conversation['id'],
  ): SuggestedQuestionAnswer | false {
    let request = this.requests.get(conversationId);

    if (request === undefined || request.status !== 'response-received') {
      return false;
    }

    let { suggested_question_answer_id, question, answer, locale } = request.response;
    return { question, answer, locale, id: suggested_question_answer_id };
  }

  requestForConversation(conversationId: Conversation['id']): RequestStatus | undefined {
    return this.requests.get(conversationId);
  }

  async submitSuggestion(
    conversationId: Conversation['id'],
    original: SuggestedQuestionAnswer,
    suggestion: QuestionAnswer,
  ) {
    this.requests.set(conversationId, { status: 'submitted' });
    this.closeModal();

    let response = await post(
      `/ember/inbox/conversations/${conversationId}/fin_suggest_qa?app_id=${this.session.workspace.id}`,
      {
        suggested_question_answer_id: original.id,
        question: suggestion.question,
        answer: suggestion.answer,
        locale: suggestion.locale,
        original_question: original.question,
        original_answer: original.answer,
      },
    );

    this.intercomEventService.trackAnalyticsEvent({
      object: 'inbox_fin_question_answers',
      action: 'submitted_suggestion',
      conversation_id: conversationId,
      content_snippet_id: response.content_snippet_id,
    });

    // we're dropping submitted_suggestion for some reason, send another event at the same
    // time with a different name to see if that comes through at a different rate
    // https://github.com/intercom/intercom/issues/294092
    this.intercomEventService.trackAnalyticsEvent({
      object: 'inbox_fin_question_answers',
      action: 'submitted_2',
    });

    this.snackbar.notify(
      this.intl.t('inbox.fin-question-answer-modal.notifications.suggested.content'),
      {
        buttonLabel: this.intl.t('inbox.fin-question-answer-modal.notifications.suggested.button'),
        onButtonClick: () => {
          this.router.transitionTo(
            'apps.app.automation.resolution-bot.fin-content',
            this.session.workspace.id,
            {
              queryParams: {
                // filters the page to only show snippets
                selectedObjectTypes: EntityType.ContentSnippet,
                // opens the specific snippet in the sidebar
                id: response.content_snippet_id,
                content: objectNames[EntityType.ContentSnippet],
              },
            },
          );
        },
        contentComponent: 'inbox2/fin-question-answer-modal/suggested-snackbar',
      },
    );
  }

  async dismissSuggestion(
    conversationId: Conversation['id'],
    original: SuggestedQuestionAnswer,
    reason?: DismissReason,
  ) {
    this.requests.set(conversationId, { status: 'dismissed' });
    this.closeModal();

    await post(
      `/ember/inbox/conversations/${conversationId}/fin_dismiss_qa?app_id=${this.session.workspace.id}`,
      {
        suggested_question_answer_id: original.id,
        original_question: original.question,
        original_answer: original.answer,
        reason,
      },
    );

    this.intercomEventService.trackAnalyticsEvent({
      object: 'inbox_fin_question_answers',
      action: 'dismissed_suggestion',
      conversation_id: conversationId,
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    finQuestionAnswers: FinQuestionAnswers;
    'fin-question-answers': FinQuestionAnswers;
  }
}
